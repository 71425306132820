/** @jsx jsx */

import { jsx, Spinner } from 'theme-ui';
import { ViewElement } from "./atoms";
import { useState, useEffect, useRef } from 'react';
import { ImageCard } from "../components/atoms";
import { carouselButtonSx } from './joinform/elements';
import { graphql, useStaticQuery } from "gatsby";

const noImage = require("../images/noimage.png")

export class MediaData {
    id: string;
    caption: string;
    mediaType: string;
    mediaUrl: string;
    timestamp: string;
    thumbnailUrl: string;
    permalink: string;

    constructor(id: string, caption: string, mediaType: string, mediaUrl: string, timestamp: string, thumbnail_url: string, permalink: string) {
        this.id = id;
        this.caption = caption;
        this.mediaType = mediaType;
        this.mediaUrl = mediaUrl;
        this.timestamp = timestamp;
        this.thumbnailUrl = thumbnail_url;
        this.permalink = permalink;
    }
}

export const InstagramModule: ViewElement<{
    limit: number
    bgColour: string
    isRtl: boolean
}> = ({ limit, bgColour, isRtl, ...props }) => {
    const sideLimit = 40;

    const [isLoaded, setIsLoaded] = useState(false)
    const [offset, setOffset] = useState(0)
    const [limitOffset, setLimitOffset] = useState(0)
    const [posts, setPosts] = useState<MediaData[]>([])
    const [postsWidth, setPostsWidth] = useState(0)
    const [screenWidthCache, setScreenWidthCache] = useState(0)

    const chevronRight = require('../images/chevron-right.png');

    const offsetRef = useRef(0)
    const limitOffsetRef = useRef(0)

    offsetRef.current = offset;
    limitOffsetRef.current = limitOffset;

    const fadeColour = bgColour.replace("#", "");
    const red = parseInt(fadeColour.substring(0, 2), 16).toString();
    const green = parseInt(fadeColour.substring(2, 4), 16).toString();
    const blue = parseInt(fadeColour.substring(4, 6), 16).toString();

    const queryResponse = useStaticQuery(instaQuery);

    const getInstaFeedPosts = () => {
        try {
            return queryResponse.igDataJson.data.map(d => {
                return new MediaData(d.id, d.caption, d.media_type, d.media_url, d.timestamp, d.thumbnail_url, d.permalink)
            });
        } catch (e) {
            return "Error";
        }
    }

    const hideArrow = (direction: string) => {
        const postsContent = document.getElementById("posts-content")?.parentNode;
        if (postsContent) {
            const arrow = postsContent.querySelector(direction);
            if (arrow) {
                if (!arrow.classList.contains('hidden')) {
                    arrow.classList.add('hidden');
                }
            }
        }
    }

    const showArrow = (direction: string) => {
        const postsContent = document.getElementById("posts-content")?.parentNode;
        if (postsContent) {
            const arrow = postsContent.querySelector(direction);
            if (arrow) {
                if (arrow.classList.contains('hidden')) {
                    arrow.classList.remove('hidden');
                }
            }
        }
    }

    const handleClick = (amount: number) => {
        try {
            const postsContent = document.getElementById("posts-content");
            if ((postsWidth == 0 && window !== undefined && document.querySelector('html') && postsContent && postsContent!.children.length > 0) || (window !== undefined && window.innerWidth != screenWidthCache)) {
                const screenWidthEm = window.innerWidth / parseFloat(getComputedStyle(document.querySelector('html')!)['font-size']);
                let childPostWidth = 370;
                if (screenWidthEm <= 40) {
                    childPostWidth = 170
                } else if (screenWidthEm <= 52) {
                    childPostWidth = 270
                }
                setPostsWidth((childPostWidth * postsContent!.children.length) + sideLimit);
                setScreenWidthCache(window.innerWidth);
            }
        } catch (e) {
            setPosts([])
        }

        let moveAmount = Math.round(postsWidth / limit) * amount;
        if (moveAmount == 0) {
            moveAmount = 250 * amount;
        }

        if (isRtl) {
            if ((limitOffsetRef.current + moveAmount) <= -sideLimit) {
                setLimitOffset(-sideLimit);
                hideArrow(".rightButton");
                showArrow(".leftButton");
            } else if (window !== undefined && postsWidth != 0 && ((limitOffsetRef.current + moveAmount) >= (-window.innerWidth + postsWidth + sideLimit))) {
                setLimitOffset(-window.innerWidth + postsWidth + sideLimit);
                hideArrow(".leftButton");
                showArrow(".rightButton")
            } else {
                showArrow(".leftButton");
                showArrow(".rightButton");
                setLimitOffset(limitOffsetRef.current + moveAmount);
            }
        } else {
            if ((limitOffsetRef.current + moveAmount) >= sideLimit) {
                setLimitOffset(sideLimit);
                hideArrow(".leftButton");
                showArrow(".rightButton");
            } else if (window !== undefined && postsWidth != 0 && ((limitOffsetRef.current + moveAmount) <= (window.innerWidth - postsWidth - sideLimit))) {
                setLimitOffset(window.innerWidth - postsWidth - sideLimit);
                hideArrow(".rightButton");
                showArrow(".leftButton");
            } else {
                showArrow(".leftButton")
                showArrow(".rightButton");
                setLimitOffset(limitOffsetRef.current + moveAmount);
            }
        }

        resolveOffset();
    }

    const checkOffset = () => {
        if (offsetRef.current != limitOffsetRef.current) {
            const minMoveSpeed = 5;
            let moveSpeed = Math.round(Math.abs(limitOffsetRef.current - offsetRef.current) / 3);
            if (moveSpeed < minMoveSpeed) {
                moveSpeed = minMoveSpeed
            }
            if ((offsetRef.current > limitOffsetRef.current - minMoveSpeed) && (offsetRef.current < limitOffsetRef.current + minMoveSpeed)) {
                setOffset(limitOffsetRef.current);
            } else {
                if (offsetRef.current > limitOffsetRef.current) {
                    setOffset(offsetRef.current - moveSpeed);
                } else {
                    setOffset(offsetRef.current + moveSpeed);
                }
            }
        }
    }

    const resolveOffset = () => {
        checkOffset();
        setTimeout(function () {
            if (offsetRef.current != limitOffsetRef.current) {
                resolveOffset();
            }
        }, 10)
    }

    useEffect(() => {
        setLimitOffset(0);
    }, [isRtl])

    useEffect(() => {
        const response = getInstaFeedPosts();
        if (response != "Error") {
            setPosts(response);
        }
        setIsLoaded(true);
    }, [])

    if (isLoaded && (posts?.length > 0)) {
        return (
            <div sx={{ position: "relative", height: ["170px", "270px", "370px"], width: "100%", paddingX: "20px", whiteSpace: "nowrap", overflowX: "hidden", overflowY: "hidden", marginTop: ["0px", "0px", "15px"] }}>
                <div id="posts-content" sx={{ left: offset + "px", position: "relative" }}>
                    {posts.map(p => (
                        <InstagramPost mediaData={p} />
                    ))}
                </div>
                <div sx={{ position: "absolute", height: "100%", width: ["40px", "80px", "120px"], left: "0px", top: "0px", background: "linear-gradient(to right, rgba(" + red + ", " + green + ", " + blue + ", 1), rgba(" + red + ", " + green + ", " + blue + ", 0))" }}></div>
                <div sx={{ position: "absolute", height: "100%", width: ["40px", "80px", "120px"], right: "0px", top: "0px", background: "linear-gradient(to left, rgba(" + red + ", " + green + ", " + blue + ", 1), rgba(" + red + ", " + green + ", " + blue + ", 0))" }}></div>
                <div sx={carouselButtonSx} className="leftButton" onClick={() => handleClick(1)}>
                    <img src={chevronRight} />
                </div>
                <div sx={carouselButtonSx} className="rightButton" onClick={() => handleClick(-1)}>
                    <img src={chevronRight} />
                </div>
            </div>
        )
    } else if (isLoaded) {
        return (
            null
        )
    } else {
        return (
            <div sx={{ color: "#280000", height: ["60px", "120px", "180px"], width: "100%", paddingX: "10px", fontSize: ["10px", "15px", "20px"], lineHeight: ["60px", "120px", "180px"], textAlign: "center" }}>
                <Spinner />
            </div>
        )
    }
}

export const InstagramPost: ViewElement<{
    mediaData: MediaData
}> = ({ mediaData, ...props }) => {
    return (
        <a href={mediaData.permalink}>
            <div sx={{ display: "inline-block" }}>
                <ImageCard sx={{ width: ["150px", "250px", "350px"], height: ["150px", "250px", "350px"], margin: "10px" }} src={(mediaData.mediaType == "VIDEO" ? (mediaData.thumbnailUrl == "" ? noImage : mediaData.thumbnailUrl) : (mediaData.mediaUrl == "" ? noImage : mediaData.mediaUrl))} />
            </div>
        </a>
    )
}

const instaQuery = graphql`
    query instaPosts {
        igDataJson(data: {elemMatch: {}}) {
            data {
                caption
                id
                media_type
                media_url
                permalink
                thumbnail_url
                timestamp
            }
        }
    }
  `